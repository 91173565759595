body {
  background-color: $white-color;
  margin: 0 auto;
}

@include media-large {
  body,
  #root {
    padding: 0;
  }

  // Elemente mit max-width

  .main__banner > h2,
  .main__teaser,
  .main__projekte > .projekte--liste,
  .main__spektrum,
  .main__strategie > div,
  .projects__header > div,
  .projects__images,
  .projects__footer > div,
  .footer__adresse > p,
  .footer__adresse > div {
    max-width: $breakpoint-medium;
    margin: 0 auto;
  }
}

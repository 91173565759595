/*
*******************************************************
 Mixins
*******************************************************
*/

// Set font-size and line-height
@mixin set-font-size($font-size, $line-height: $font-size) {
  font-size: $font-size;
  line-height: $line-height;
}

// Set font
@mixin set-font(
  $font-family,
  $font-weight,
  $font-size,
  $line-height: $font-size
) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
  @include set-font-size($font-size, $line-height);
}

@mixin media-small {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin media-medium {
  @media (min-width: #{$breakpoint-mobile + 1px}) {
    @content;
  }
}

@mixin media-large {
  @media (min-width: #{$breakpoint-medium  + 1px}) {
    @content;
  }
}

.spektrum__vcards {
  padding: space(5) 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 598px;
  margin: 0 auto;

  @media (min-width: 942px) {
    max-width: none;
  }
}

.spektrum__vcards--card {
  width: 100%;
  padding: space(2) 0 space(5) 0;
  > h3,
  > p {
    @include set-font-size(1.15rem, 1.4rem);
  }

  &:last-of-type {
    padding-bottom: space(2);
  }

  > img {
    width: 65px;
    padding-bottom: space(2);
  }

  @include media-medium {
    width: 180px;
    padding: space(5) space(2) space(5) space(2);

    > img {
      width: 75px;
      padding-bottom: space(8);
    }
  }
}

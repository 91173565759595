.main__banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-banner-height;
  background-size: 100% 165px;
  background-position: center;

  > h2 {
    flex: 1 0 100%;
    color: $white-color;
    text-align: center;
    @include set-font-size(1.9em, 1.2em);
    font-weight: 600;
    margin: 0;
  }

  @include media-medium {
    height: $header-banner-height-medium;
    background-size: cover;

    > h2 {
      padding-top: 100px;
      max-width: 650px;
    }
  }

  @media (min-width: 2150px) {
    background-size: 102% 450px;
  }
}

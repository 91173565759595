.main__teaser {
  padding: space(11) 0 space(9) 0;

  @include media-medium {
    padding: space(18) space(10) space(16) space(10);
  }

  > .main__teaser--stoerer {
    position: relative;
    > img {
      position: absolute;
      top: $header-stoerer-position;
      right: space(2);
      width: 78px;
      height: 78px;
      transform: rotate(-20deg);

      @include media-medium {
        top: $header-stoerer-position-medium;
        width: 110px;
        height: 110px;
        right: space(6) * -1;
      }
    }
  }
}

.main__teaser > p > span {
  color: $green-color;
  font-weight: bold;
  margin-right: space(1);
}

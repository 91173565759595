* {
  font-family: $base-font;
}

p,
a,
li,
span {
  @include set-font-size($root-font-size, 1.8rem);
  color: $font-color;
}

h1,
h2,
h3 {
  color: $font-color;
}

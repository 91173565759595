// Imports

@import "projects/client-data";

.main__projects {
  padding-bottom: space(5);
  background-color: #e6e6e6;
}

.projects__header {
  height: $header-banner-height;
  background-size: 900px 228px;
  background-position: bottom center;

  @include media-medium {
    padding-top: space(10);
    height: 170px;
    background-size: cover;
  }
}

.main__strategie {
  padding: space(5) 0 space(8) 0;
  background-size: cover;
  background-position: top center;

  @include media-medium {
    min-height: 490px;
  }

  > div {
    > img {
      width: 210px;
    }

    > h2,
    > p {
      color: $white-color;
    }

    > h2 {
      @include set-font-size(1.5rem, 1.5rem);
      padding: space(5) 0;
    }

    @include media-medium {
      padding: 0 space(10);
      padding-top: space(14);

      > p {
        padding: 0 space(0);
        @include set-font-size(1.42rem, 1.9rem);
      }

      > img {
        width: 280px;
      }
    }
  }
}

.footer__adresse {
  background-color: $green-color;
  padding: space(4.5) $site-margin-h space(3) $site-margin-h;
}

.address--links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    flex: 0 1 100%;
    text-align: center;
  }
}

.button.button--link {
  padding: space(0.1) space(2);
  margin: space(0.8) space(1);
  > a {
    @include set-font-size(1.1rem, 1.5rem);
  }

  &:hover {
    border-color: $green-color;
    background-color: $white-color;

    > a {
      color: $green-color;
    }
  }
}

.footer__adresse > ul {
  list-style: none;
  text-align: center;

  > li {
    color: $white-color;
    @include set-font-size(1.1rem, 1.5rem);
  }
}

@include media-medium {
  .footer__adresse {
    padding: space(6) $site-margin-h space(6) $site-margin-h;

    > div {
      position: relative;
    }
  }
}

@include media-large {
  .footer__adresse {
    > div > p {
      display: inline;
      color: $white-color;
      @include set-font-size(1.15rem, 1.2rem);
      padding-left: space(6);
      padding-right: space(1);

      > .button.button--link {
        padding: space(0.1) space(2);
        margin-left: space(2);

        > a {
          @include set-font-size(1.1rem, 1.5rem);
        }
      }
    }

    .datenschutz {
      margin-top: 115px;
    }
  }
}

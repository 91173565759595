//Position vom Teaser

$stoerer-teaser-right: space(54);
$stoerer-teaser-bottom: space(6);
$stoerer-teaser-size: 110px;

.footer__stoerer--container {
  display: none;
  @media all and (min-width: 660px) {
    display: block;
  }
}

@include media-medium {
  .footer__stoerer--teaser-hover-container {
    background-color: rgba(254, 254, 254, 0.72);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .footer__stoerer--teaser,
  .footer__stoerer--teaser-hover {
    position: absolute;
    left: 50%;
    top: space(76);
    margin-left: 30%;
    cursor: pointer;
    width: $stoerer-teaser-size; //355 full size
    height: $stoerer-teaser-size;
  }

  .footer__stoerer--teaser {
    > img {
      width: $stoerer-teaser-size;
      height: $stoerer-teaser-size;
      transform: rotate(-30deg);
    }
  }

  .footer__stoerer--teaser-hover {
    opacity: 1;

    background-color: #4998dc;
    border: 0 solid #4998dc;
    border-radius: 50%;
    width: $stoerer-teaser-size; //355 full size
    height: $stoerer-teaser-size;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    > div {
      text-align: center;
    }

    > div > p {
      @include set-font-size(1.15rem, 1.5rem);
      color: $white-color;
      padding: space(1) space(4);

      > span {
        @include set-font-size(1.3rem, 1.5rem);
        color: $white-color;
        font-weight: 700;
      }
    }

    > div > img {
      padding-top: space(3);
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  }
}

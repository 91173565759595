.projekte--liste > a {
  margin: space(0) space(2) space(1) space(2);
  box-sizing: border-box;

  @include media-medium {
    margin: space(0.5) space(1);
  }

  @include media-large {
    margin: space(1);
  }
}

.projekte--karte {
  position: relative;

  overflow: hidden;
  width: 100%;

  > img {
    width: 100%;
  }

  @include media-small {
  }

  @include media-medium {
  }

  @include media-large {
    height: 100%;

    > img {
      width: 100%;
      display: flex;
      height: 100%;
    }
  }
}

.karte--content {
  color: $white-color;

  > h3 {
    color: $white-color;
    @include set-font-size(1.6rem, 1.2rem);
  }

  > p {
    padding: space(2) space(2) 0 space(2);
    color: $white-color;
    @include set-font-size(1.5rem, 1.7rem);
  }
}

.karte--layer-black {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 97.5%;
  @include media-medium {
    height: 98%;
  }
  @include media-large {
    height: 100%;
  }
}

.karte--layer-green {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(6, 134, 28, 0.6);
}

/*
*******************************************************
 Globals
*******************************************************
*/
@use "sass:math";
$assets-path: "../assets/";

/*
*******************************************************
 Breakpoints
*******************************************************
*/

$breakpoint-mobile: 414px;
$breakpoint-medium: 960px;

/*
*******************************************************
 Spacing
*******************************************************
*/

$space: 5px;
$site-margin-h: $space * 3;
$site-margin-v: 0;

// Feste Höhen im Header

$header-logo-height: 95px;
$header-logo-height-medium: 95px;
$header-banner-height: 165px;
$header-banner-height-medium: 440px; //340
$header-stoerer-position: math.div($header-banner-height, -1.9);
$header-stoerer-position-medium: math.div($header-banner-height-medium, -2.7);

//Feste Höhen im Footer
$footer-height: 320px;
$footer-height-medium: 490px;

/*
*******************************************************
 Colors
*******************************************************
*/

$white-color: white;
$black-color: black;
$grey-color: #ccc; //rgba(204, 204, 204, 0.4);

$green-color: #06861c;

$base-background-color: #454545;
$font-color: #555555; //#4e4e4e;
// icon color 7d4685ff, hover cbcbe7ff

/*
*******************************************************
 Fonts
*******************************************************
*/

// Fonts to use
$fallback-font: sans-serif;
$base-font: "proxima-nova", $fallback-font;
$title-font: "house-script", $fallback-font;

// Define font-weight
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;

// Root font-size. From this we'r sizing the rest of the elements relative with em
$root-font-size: 22px;

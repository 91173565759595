.main__datenschutz {
  padding: 65px 50px 0;
  text-align: justify;

  h1,
  h2,
  h3,
  h4 {
    color: #06861c;
    margin-top: 15px;
    word-wrap: break-word;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin-bottom: 5px;
  }

  ul {
    margin-left: 25px;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      margin: 10px 0;
    }
  }
}

.header--logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-logo-height;
  padding: space(2) 0 0 0;

  @include media-medium {
    height: $header-logo-height-medium;
    padding: space(4) 0 0 0;
  }

  #logo {
    width: 220px;
    height: 75.7167;
    @include media-medium {
      width: 300px;
      height: 103.267px;
    }
  }
}

@include media-medium {
  header {
    height: 60px;
  }
}

.button {
  display: inline-block;
  margin: 2.5px;
  padding: 2.5px 15px;
  border: 1px solid $white-color;
  border-radius: 25px;
  color: $white-color;
}

.button.button--link {
  a {
    color: $white-color;
    text-decoration: none;
    width: 100%;
    height: 100%;
  }
}

.projects__header > div,
.projects__footer > div {
  padding: space(7) $site-margin-h space(0) $site-margin-h;
  display: flex;
  flex-wrap: wrap;

  @include media-medium {
    padding-top: space(12);
    padding-bottom: space(2);
    width: 95%;
    align-items: center;
    margin: 0 auto;
    height: $header-banner-height - 70px;
  }

  > .client--icons {
    flex: 1;
    min-width: 50%;

    > img {
      cursor: pointer;
      width: 30px;
    }
  }

  @include media-medium {
    > .client--icons {
      min-width: 0;
      display: flex;

      > img {
        width: 43px;
      }
    }

    > .client--icons:last-of-type {
      justify-content: flex-end;
    }
  }

  > .client--icons:first-of-type {
    text-align: left;
    order: 0;
    > img {
      transform: rotate(180deg);
    }
  }

  > .client--icons:last-of-type {
    text-align: right;
    order: 1;
    @include media-medium {
      order: 2;
    }
  }
}

.projects__footer > div {
  padding-top: space(4);
}

.client--data {
  padding-top: space(3);
  order: 2;
  flex: 15;
  text-align: left;

  > p {
    @include set-font-size(1.1rem, 1.4rem);

    > span {
      @include set-font-size(1.1rem, 1.4rem);
      font-weight: 700;
      padding-right: space(2);
      color: #4c4c4c;
    }
  }

  @include media-medium {
    padding: space(5) 0;
    order: 1;
    max-width: 75%;

    > p {
      @include set-font-size(1.35rem, 1.6rem);

      > span {
        display: inline-block;
        @include set-font-size(1.4rem, 1.6rem);
        font-weight: 700;
        width: 100px;
      }
    }
  }
}

.projects__images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: space(1) 0 0 0;
  > img {
    width: 93%;
    margin: space(1.5) 0;
  }

  @include media-medium {
    padding: space(1) space(5) 0 space(5);
    > img {
      width: 100%;
    }
  }
}

@include media-medium {
  .projects__footer > div {
    padding-top: space(4);
  }
}

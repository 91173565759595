@import "footer/address";
@import "footer/stoerer-teaser";

.datenschutz {
  text-align: center;
  margin-bottom: space(1);

  a {
    @include set-font-size(1rem, 1rem);
    text-decoration: none;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.footer__kontakt {
  text-align: center;
  padding: space(8) $site-margin-h space(17) $site-margin-h;

  .kontakt--text > h2 {
    color: $green-color;
    padding-bottom: space(3);
    @include set-font-size(1.9rem, 1.9rem);
    font-weight: 600;
  }

  .kontakt--piktogramme > a > img {
    width: 65px;
    margin: space(6) space(3) 0 space(3);
  }
}

@include media-medium {
  footer {
    width: 100%;
    margin: 0;
    position: relative;
  }

  .footer__kontakt {
    padding: space(20) 0 space(17) 0;
    height: 260px;
  }

  .kontakt--text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > p {
      padding: 0 29%;
    }
  }
}

.main__spektrum {
  padding: space(8) 0 0 0;

  @include media-medium {
    padding: space(13) space(10) 0 space(10);
  }

  > h2 {
    color: $green-color;
  }
}

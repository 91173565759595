// Imports

@import "home/banner";
@import "home/teaser";
@import "home/projects";
@import "home/spektrum";
@import "home/project-card";
@import "home/spektrum-card";
@import "home/strategie";

// Constants

// Styles

main {
  text-align: center;
}

@include media-small {
  .main__strategie > div,
  .main__teaser > p,
  .main__spektrum {
    padding-left: $site-margin-h;
    padding-right: $site-margin-h;
  }
}

.main__projekte {
  padding: space(2) 0 space(2) 0;
  background-color: $grey-color;

  @include media-medium {
    padding-bottom: space(9);
  }

  > h2 {
    padding: space(5) 0;
    @include set-font-size(1.4rem, 1.2rem);
  }
}

.main__projekte > .projekte--liste {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: space(1);
  max-width: 1250px;
}

@include media-medium {
  .main__projekte > .projekte--liste {
    padding: 0 space(1);
  }

  .main__projekte > .projekte--liste > a {
    flex: 1 1 48%;
  }
}

@include media-large {
  .main__projekte > .projekte--liste {
    padding: 0 space(4);
  }

  .main__projekte > .projekte--liste > a {
    flex: 1 1 32%;
  }
}
